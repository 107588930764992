import React from 'react';
import { Card } from 'antd';
import Layout from '../components/layout/public';
import Hero from '../components/public/page-hero';
import SEO from '../components/seo';
import Content from '../components/content';
import Faq from '../components/public/faq';

const TosPage = () => (
	<Layout>
		<SEO title="Terms Of Service"/>

		<Hero title={'Terms Of Service'} style={{
			marginBottom: 50,
		}}/>

		<Content>
			<Card title={'Last update 5-Mar-2019'} style={{ marginBottom: 80 }}>
				Your use of any of the services offered by &nbsp;<u>dnsadblock.com</u>&nbsp;, is
				contingent upon your
				adherence to the following terms of service, which we refer to as the
				agreement. &nbsp;<u>dnsadblock.com</u>&nbsp; reserves the right to update and refine the
				Agreement at
				any time without prior notice. By accessing or using the service, you are indicating that you
				are aware of the Agreement and consent to abide by it Failure to comply with the Agreement
				negates any service commitment on &nbsp;<u>dnsadblock.com</u>&nbsp;'s end and may result
				in the
				termination of your membership.
				<br/>
				<br/>
				<br/> <b>1. DESCRIPTION OF SERVICE AND MEMBERSHIP GUIDELINES</b>
				<br/>
				<br/>
				&nbsp;<u>dnsadblock.com</u>&nbsp; provides its members with the ability to retrieve
				DNS information regarding domains, among other benefits.
				In exchange for this service, &nbsp;<u>dnsadblock.com</u>&nbsp; charges its members a
				monthly fee. Members must be at least 18 years old (or have the explicit permission
				of a parent/guardian) and competent to enter into a contract to use the Service.
				Members must also provide current, complete, and accurate personal information when
				registering with the service and maintain it accordingly.
				<br/>
				<br/>
				<br/> <b>2. MEMBER ACCOUNT, PASSWORD, AND SECURITY</b>
				<br/>
				<br/> To open an account, you must complete the registration process and successfully complete
				the payment authorization from our offered payment companies. You are entirely responsible for
				maintaining the confidentiality of your password and account. Furthermore, you are entirely
				responsible for any and all activities that occur through the use of your account and/or
				password. You agree to notify &nbsp;<u>dnsadblock.com</u>&nbsp; immediately of any
				unauthorized use of your account or any other security breaches.
				<br/>
				<br/>
				<br/> <b>3. MEMBER PRIVACY</b>
				<br/>
				<br/> It is &nbsp;<u>dnsadblock.com</u>&nbsp;'s policy to respect the privacy of its
				members. &nbsp;<u>dnsadblock.com</u>&nbsp; will not monitor, edit, or disclose any
				personal information about you or your use of the Service, luding its contents,
				without your prior permission unless &nbsp;<u>dnsadblock.com</u>&nbsp; has reason to
				believe that such action is necessary to:
				(1) conform to legal requirements or comply with legal process; (2) protect and defend
				the rights or property of &nbsp;<u>dnsadblock.com</u>;&nbsp; (3) enforce the Agreement;
				or (4) to protect the interests of its other Members. You agree that &nbsp;
				<u>dnsadblock.com</u>&nbsp; may access your account, luding its contents,
				as stated above or in order to respond to service or technical issues.
				<br/>
				<br/>
				<br/>
				&nbsp;<u>dnsadblock.com</u>&nbsp; will never sell or disclose your data to any third
				party unless otherwise stated. The kept logs are there to provide you with insight
				into the history of your own data. These logs are regularly deleted (each 3 months)
				from our database.
				<br/>
				<br/>
				<br/> <b>4. MEMBER CONDUCT</b>
				<br/>
				<br/> As a condition of your use of the Service, you warrant that you will not utilize the
				Service for any purpose that is unlawful or prohibited by these terms, conditions, and notices.
				By accessing any content (luding information, data, text, software, music, sound, photographs,
				graphics, video, or messages) through the &nbsp;<u>dnsadblock.com</u>&nbsp; Service, you
				warrant
				that: (1) you are the owner of such Content, or have been granted all the rights necessary from
				the Content owner, and (2) that the use of such Content will not infringe the intellectual
				property rights, or otherwise violate the rights, of any third party. By submitting Content to
				&nbsp;<u>dnsadblock.com</u>&nbsp;, you automatically grant
				&nbsp;<u>dnsadblock.com</u>&nbsp; (or warrant that the Content owner has granted) a
				nonexclusive,
				royalty-free license to use, modify, copy, duplicate, transmit, publicly display, publish,
				create derivative works of, distribute, or transfer any such Content, for any purpose whatsoever
				without any compensation to you.
				<br/>
				<br/> YOU AGREE TO USE THE SERVICE ONLY TO PERFORM WORK ON YOUR OWN BEHALF. ANY UNAUTHORIZED OR
				COMMERCIAL USE OF THE SERVICE, OR THE RESALE OF ITS SERVICES, IS EXPRESSLY PROHIBITED.
				<br/>
				<br/>
				<br/>
				<br/> PERSONAL AND NONCOMMERCIAL USE LIMITATION
				<br/>
				<br/> You may not modify, copy, distribute, transmit, display, perform, reproduce, publish,
				license, create derivative works from, transfer, or sell any materials, information, software,
				products or services obtained from this web site and Service without the express written
				permission of
				&nbsp;<u>dnsadblock.com</u>&nbsp;, . and/or the rightful owner or licensor of such
				materials,
				information, software, products or services.
				<br/>
				<br/> You agree to abide by all applicable local, state, national, and international
				laws/regulations and are solely responsible for all the acts or omissions that occur through
				your account or password, luding the content of your transmissions through the Service. You may
				not use the Service in any manner which could damage, disable, overburden, or impair
				any &nbsp;<u>dnsadblock.com</u>&nbsp; Site/Service(s) (or the network(s) connected to
				any &nbsp;<u>dnsadblock.com</u>&nbsp; Site/Service(s)) or interfere with any other
				party's use and
				enjoyment of any
				&nbsp;<u>dnsadblock.com</u>&nbsp; Site/Service(s). You may not attempt to gain
				unauthorized access to
				any &nbsp;<u>dnsadblock.com</u>&nbsp; Site/Service, other accounts, computer systems or
				networks
				connected to any
				&nbsp;<u>dnsadblock.com</u>&nbsp; Site/Service, through hacking, password mining or any
				other means.
				You may not obtain or attempt to obtain any materials or information through any means not
				intentionally made available through the
				&nbsp;<u>dnsadblock.com</u>&nbsp; Site/Service(s). You are responsible for any content
				that you post
				or transmit on or through the Service. By way of example, and not as a limitation, you agree not
				to:
				<br/>
				<br/> * Use the Service in connection with surveys, contests, pyramid schemes, chain letters,
				junk e-mail, spamming, or any duplicative or unsolicited messages (commercial or otherwise);
				<br/> * Defame, abuse, harass, stalk, threaten, or otherwise violate any of the legal rights of
				others;
				<br/> * Publish, distribute, or disseminate any inappropriate, profane, defamatory, infringing,
				obscene, indecent, or unlawful material/information;
				<br/> * Advertise or offer to sell or buy any goods or services for any non-personal purpose;
				<br/> * Harvest or otherwise collect information about others, luding e-mail addresses, without
				their consent;
				<br/> * Create a false identity for the purpose of misleading others as to the identity of the
				sender or the origin of a message;
				<br/> * Transmit or upload any material that contains viruses, trojan horses, worms, time bombs,
				cancelbots, or any other harmful or deleterious programs;
				<br/> * Transmit or upload any material that contains software or other material protected by
				intellectual property laws, rights of privacy or publicity or any other applicable law unless
				you own or control the rights thereto or have received all necessary consents;
				<br/> * Interfere with or disrupt networks connected to the Service or violate the regulations,
				policies or procedures of such networks;
				<br/> * Attempt to gain unauthorized access to the Service, other accounts, computer systems, or
				networks connected to the Service through password mining or any other means;
				<br/> * Interfere with another Member's use and enjoyment of the Service or another individual's
				or entity's use and enjoyment of similar services;
				<br/> * Violate &nbsp;<u>dnsadblock.com</u>&nbsp;'s own copyrights or take any part of
				the service or
				its written
				components and manipulate or copy it for your own use.
				<br/>
				<br/>
				&nbsp;<u>dnsadblock.com</u>&nbsp; has no obligation to monitor the Service or any user's
				use thereof
				or retain the content of any user session. However,
				&nbsp;<u>dnsadblock.com</u>&nbsp; reserves the right at all times to monitor, review,
				retain and/or
				disclose any information as necessary to satisfy any applicable law, regulation, legal process,
				or governmental request.
				<br/>
				<br/> You will comply with all applicable laws and regulations regarding online conduct and
				content. You will not use the Service to collect, store, or use information about others for the
				purpose of sending unsolicited or unauthorized communications. If you violate any of these terms
				and conditions, your permission to use the
				&nbsp;<u>dnsadblock.com</u>&nbsp; Site/Service(s) automatically terminates.
				<br/>
				<br/>
				<br/> <b>5. THIRD PARTY CONTENT</b>
				<br/>
				<br/> Any information, data, text, software, music, sound, photographs, graphics, video,
				messages or any other materials retrieved from third party web sites is not under the control of
				&nbsp;<u>dnsadblock.com</u>&nbsp; and
				&nbsp;<u>dnsadblock.com</u>&nbsp; is not responsible for the contents accessed from any
				site or any
				link contained in a linked site or any link site or any link contained in a linked site, or any
				changed or updates to such sites.
				&nbsp;<u>dnsadblock.com</u>&nbsp; does not imply endorsement of the content provided by
				other sites
				or any association with their operators.
				<br/>
				<br/>
				<br/> <b>6. DISCLAIMERS/LIMITATION OF LIABILITY</b>
				<br/>
				<br/> By using the
				&nbsp;<u>dnsadblock.com</u>&nbsp; web site or any applications, software, and content
				contained
				therein, you agree that use of the Service is entirely at your own risk.The information and
				services luded in or available through the Service may lude inaccuracies or typographical
				errors. Changes are periodically made to the information herein.
				&nbsp;<u>dnsadblock.com</u>&nbsp; and/or its respective suppliers may make improvements
				and/or
				changes in the Service at any time.
				<br/>
				<br/>
				&nbsp;<u>dnsadblock.com</u>&nbsp; does not represent or warrant that the Service will be
				uninterrupted or error-free, that defects will be corrected, or that the Service or the server
				that makes it available are free of viruses or other harmful components.
				&nbsp;<u>dnsadblock.com</u>&nbsp; does not warrant or represent that the use or the
				results of the
				use of the Service or the materials made available as part of the Service will be correct,
				accurate, timely, or otherwise reliable.
				<br/>
				<br/> You specifically agree that
				&nbsp;<u>dnsadblock.com</u>&nbsp; shall not be responsible for unauthorized access to or
				alteration
				of your transmissions or data, any material or data sent or received or not sent or received, or
				any transactions entered into through the Service. You specifically agree that
				&nbsp;<u>dnsadblock.com</u>&nbsp; is not responsible or liable for any threatening,
				defamatory,
				obscene, offensive, or illegal content or conduct of any other party or any infringement of
				another's rights, luding intellectual property rights. You specifically agree that
				&nbsp;<u>dnsadblock.com</u>&nbsp; is not responsible for any content sent using and/or
				luded in the
				Service by any third party.
				<br/>
				<br/>
				&nbsp;<u>dnsadblock.com</u>&nbsp; AND/OR ITS RESPECTIVE SUPPLIERS MAKE NO REPRESENTATIONS
				ABOUT THE
				SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE SERVICE FOR ANY PURPOSE.
				THE SERVICE IS PROVIDED "AS IS" AND HAS NO WARRANTY OF ANY KIND.
				&nbsp;<u>dnsadblock.com</u>&nbsp; AND/OR ITS RESPECTIVE SUPPLIERS HEREBY DISCLAIM ALL
				WARRANTIES AND
				CONDITIONS WITH REGARD TO THE SERVICE, LUDING ALL IMPLIED WARRANTIES AND CONDITIONS OF
				MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. IN NO EVENT SHALL
				&nbsp;<u>dnsadblock.com</u>&nbsp; AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT,
				INDIRECT, PUNITIVE,
				IDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER LUDING, WITHOUT LIMITATION,
				DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR
				PERFORMANCE OF THE SERVICE OR RELATED WEB SITES, WITH THE DELAY OR INABILITY TO USE THE SERVICE
				OR RELATED WEB SITES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION,
				SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SERVICE, OR OTHERWISE
				ARISING OUT OF THE USE OF THE SERVICE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
				LIABILITY OR OTHERWISE, EVEN IF
				&nbsp;<u>dnsadblock.com</u>&nbsp; OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE
				POSSIBILITY OF
				DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY
				FOR CONSEQUENTIAL OR IDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE
				DISSATISFIED WITH ANY PORTION OF THE SERVICE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND
				EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SERVICE AND ITS RELATED WEB SITES.
				<br/>
				<br/>
				<br/> <b>7. INDEMNIFICATION</b>
				<br/>
				<br/> You agree to indemnify and
				&nbsp;<u>dnsadblock.com</u>&nbsp;, its parents, subsidiaries, affiliates, officers and
				employees
				immune from any claim, demand, or damage, luding reasonable attorneys' fees, that are asserted
				by any third party due to or arising out of your use of or conduct on the Service. You agree
				that we will respond to your complaints only with a refund for the current and remaining months
				of your Membership agreement.
				<br/>
				<br/>
				<br/> <b>8. TERMINATION</b>
				<br/>
				<br/>
				&nbsp;<u>dnsadblock.com</u>&nbsp; may terminate your access to any part or all of the
				Service and any
				related service(s) at any time, with or without cause, with or without notice, to be effective
				immediately, for any reason whatsoever.
				&nbsp;<u>dnsadblock.com</u>&nbsp; may also terminate your account for inactivity, which
				is defined as
				failing to log in to the Service for an extended period of time, as determined by
				&nbsp;<u>dnsadblock.com</u>&nbsp;. Upon termination of the Service, your right to use the
				Service
				immediately ceases. If you wish to terminate your account, your only recourse is to discontinue
				the use of the Service.
				&nbsp;<u>dnsadblock.com</u>&nbsp; shall have no obligation to maintain any content in
				your account or
				to forward any unread or unsent workflow notification to you or any third party. This Agreement
				will continue to apply to all past use of the service by you, even if you are no longer using
				it.
				<br/>
				<br/>
				<br/> <b>9. NO SPAM; DAMAGES</b>
				<br/>
				<br/>
				&nbsp;<u>dnsadblock.com</u>&nbsp; will immediately terminate any account which it
				believes, through
				its own discretion, is transmitting or is otherwise connected with any spam or other unsolicited
				bulk e-mail. In addition, in cases where actual damages cannot be reasonably calculated se they
				are often difficult to quantify, you agree to pay
				&nbsp;<u>dnsadblock.com</u>&nbsp; liquidated damages of $5 for each piece of spam or
				unsolicited bulk
				e-mail transmitted from or otherwise connected with your account; otherwise you agree to pay
				&nbsp;<u>dnsadblock.com</u>&nbsp;' actual damages, to the extent such actual damages can
				be
				reasonably calculated.
				<br/>
				<br/>
				<br/> <b>10. PROPRIETARY RIGHTS TO CONTENT</b>
				<br/>
				<br/> You acknowledge that Content luding, but not limited to, text, software, music, sound,
				photographs, video, graphics, or other material contained in either sponsor advertisements or
				electronically distributed, commercially produced information presented to you by the Service,
				by
				&nbsp;<u>dnsadblock.com</u>&nbsp;, or
				&nbsp;<u>dnsadblock.com</u>&nbsp;' Advertisers or other content providers, is protected
				by
				copyrights, trademarks, service marks, patents or other proprietary rights and laws. You may not
				modify, copy, reproduce, republish, upload, post, transmit, or distribute in any way Content
				available through the Service and its associated Web sites, luding code and software.
				<br/>
				<br/>
				<br/> <b>11. MODIFICATIONS TO TERMS OF SERVICE</b>
				<br/>
				<br/>
				&nbsp;<u>dnsadblock.com</u>&nbsp; reserves the right to change the Agreement or policies
				regarding
				the use of the Service at any time. You agree that notification will consist of posting an
				updated version of the Agreement on this Web site. You are responsible for regularly reviewing
				the Agreement. Continued use of the Service after any such changes shall constitute your consent
				to such changes.
				<br/>
				<br/> The Service is currently accessible to Members for a monthly, bi-annually and anually fee;
				however, &nbsp;<u>dnsadblock.com</u>&nbsp; reserves the right to modify the pricing structure in
				the future. &nbsp;<u>dnsadblock.com</u>&nbsp; will notify you of any fees associated with any portion
				of the Service, and will require you to subscribe to any such portion of the Service prior to charging
				you any fees.
				<br/>
				<br/>
				<br/> You and
				&nbsp;<u>dnsadblock.com</u>&nbsp; agree that any cause of action arising out of or
				related to this
				Service must commence within one (1) year after the cause of action arose; otherwise, such cause
				of action is permanently barred. Use of the Service is unauthorized in any jurisdiction that
				does not give effect to all provisions of these terms and conditions, luding without limitation
				this paragraph. You agree that no joint venture, partnership, employment, or agency relationship
				exists between you and
				&nbsp;<u>dnsadblock.com</u>&nbsp; as a result of this agreement or use of the Service.
				&nbsp;<u>dnsadblock.com</u>&nbsp;' performance of this agreement is subject to existing
				laws and
				legal process, and nothing contained in this agreement is in derogation of
				&nbsp;<u>dnsadblock.com</u>&nbsp;' right to comply with governmental, court and law
				enforcement
				requests or requirements relating to your use of the Service or information provided to or
				gathered by
				&nbsp;<u>dnsadblock.com</u>&nbsp; with respect to such use. If any part of this agreement
				is
				determined to be invalid or unenforceable pursuant to applicable law luding, but not limited to,
				the warranty disclaimers and liability limitations set forth above, then the invalid or
				unenforceable provision will be deemed superseded by a valid, enforceable provision that most
				closely matches the intent of the original provision and the remainder of the agreement shall
				continue in effect. You agree that
				&nbsp;<u>dnsadblock.com</u>&nbsp; may communicate with you, or with others on your
				behalf, via email,
				any similar technology, or regular mail for the purposes relating to your user account, the
				Service, any software provided with the Service or any other services provided now or in the
				future by
				&nbsp;<u>dnsadblock.com</u>&nbsp; or on
				&nbsp;<u>dnsadblock.com</u>&nbsp; behalf. Unless otherwise specified herein, this
				Agreement
				constitutes the entire agreement between the user and
				&nbsp;<u>dnsadblock.com</u>&nbsp; with respect to the Service (excluding the use of any
				software
				which may be subject to an end-user license agreement) and it supersedes all prior or
				contemporaneous communications and proposals, whether electronic, oral or written, between the
				user and
				&nbsp;<u>dnsadblock.com</u>&nbsp; with respect to the Service. A printed version of this
				Agreement
				and of any notice given in electronic form shall be admissible in judicial or administrative
				proceedings based upon or relating to this Agreement to the same extent and subject to the same
				conditions as other business documents and records originally generated and maintained in
				printed form. The section titles in the Agreement are solely used for the convenience of the
				parties and have no legal or contractual significance
			</Card>

			<Faq style={{
				marginBottom: 50,
			}}/>
		</Content>
	</Layout>
);


export default TosPage;
